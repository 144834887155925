const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6934775",
    space_js: "//fyxpskb.yxsktech.com/production/t/source/onw/common/gk-pp/l.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934776",
    space_js: "//cfpbday.dimenspace.com/site/upox/common/y/openjs/x_yxq.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934777",
    space_js: "//fyxpskb.yxsktech.com/common/v/static/qpy/resource/i-m/rrr.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6934778",
    space_js: "//fyxpskb.yxsktech.com/production/wrq/z/production/jn/static/sst.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native2,
  Home_banner: Native3,
  Detail_inter: Inter,
  Detail_native: Native2,
  Detail_banner: Native3,
  List_native: Native2,
  List_banner: Native3,
  Result_inter: Inter,
  Result_native: Native2,
  Result_banner: Native3
};
